// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apie-mane-js": () => import("./../../../src/pages/apie-mane.js" /* webpackChunkName: "component---src-pages-apie-mane-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-koucingas-js": () => import("./../../../src/pages/koucingas.js" /* webpackChunkName: "component---src-pages-koucingas-js" */),
  "component---src-pages-paslaugos-js": () => import("./../../../src/pages/paslaugos.js" /* webpackChunkName: "component---src-pages-paslaugos-js" */),
  "component---src-pages-straipsniai-dato-cms-post-slug-js": () => import("./../../../src/pages/straipsniai/{DatoCmsPost.slug}.js" /* webpackChunkName: "component---src-pages-straipsniai-dato-cms-post-slug-js" */),
  "component---src-pages-straipsniai-js": () => import("./../../../src/pages/straipsniai.js" /* webpackChunkName: "component---src-pages-straipsniai-js" */)
}

